function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _object_without_properties(source, excluded) {
    if (source == null) return {};
    var target = _object_without_properties_loose(source, excluded);
    var key, i;
    if (Object.getOwnPropertySymbols) {
        var sourceSymbolKeys = Object.getOwnPropertySymbols(source);
        for(i = 0; i < sourceSymbolKeys.length; i++){
            key = sourceSymbolKeys[i];
            if (excluded.indexOf(key) >= 0) continue;
            if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
            target[key] = source[key];
        }
    }
    return target;
}
function _object_without_properties_loose(source, excluded) {
    if (source == null) return {};
    var target = {};
    var sourceKeys = Object.keys(source);
    var key, i;
    for(i = 0; i < sourceKeys.length; i++){
        key = sourceKeys[i];
        if (excluded.indexOf(key) >= 0) continue;
        target[key] = source[key];
    }
    return target;
}
import { getCookie, removeCookie, setCookie } from "/services/cookies-service";
import { setUtmClusterId, trackEvent } from "/services/bigbrain-service";
import { CLUSTER_INTERNAL_ERROR, SET_CLUSTER_EVENT, SET_CLUSTER_ID_EVENT_NAME, SET_SUB_CLUSTER_ID_EVENT_NAME } from "/constants/bigbrain-event-types";
import { CLUSTER_COOKIE, PLATFORM_ACCOUNT_CLUSTER_COOKIE, SUB_CLUSTER_COOKIE } from "/constants/cookies";
import { setUtmSubClusterId } from "./bigbrain-service";
import { CLUSTERS } from "/constants/localstorage";
import { CLUSTER, SUB_CLUSTER } from "../constants/query-params";
import { HP_COOKIE, PAGE_CONFIG, PARAM, PLATFORM_COOKIE, SET_CLUSTER_FROM_PAGE_CONFIG_REASON } from "../constants/clusters/sources";
import { PLATFORM_ACCOUNT_SUB_CLUSTER_COOKIE } from "../constants/cookies";
import { CLUSTER_TYPE, USE_CASE_TYPE } from "/constants/clusters/types.js";
import { HOMEPAGE_CLUSTERS_DATA } from "@mondaydotcomorg/clusters-service";
import { getCachedData } from "/server/services/data-service/providers/base-data-provider";
import { clusterIdToProductIdMap, WORK_MANAGEMENT_PRODUCT_ID } from "constants/products";
import { HP_TAGS_SELECTION } from "constants/clusters";
import { newUniqueArray } from "services/utils-service.js";
import orderBy from "lodash/orderBy";
const MAX_CLUSTERS = 10;
const DEFAULT_HOMEPAGE_SOURCE = "na_hp";
const OPERATIONS = {
    ADDED: "added",
    UPDATED: "updated",
    NOTHING: "nothing",
    ADDED_AND_REMOVED: "added_and_removed"
};
//* *********************************  LEGACY UTM CLUSTER AND SUBCLUSTER COOKIE SUPPORT  *************************************************************** */
// Keeping support for old utm_cluster_id and utm_sub_cluster_id_cookies - will become obsolete eventually - Platform and Bigrain will use only data points
const setFinalClusterIdAndTrackEventIfNeeded = (clusterId, reason, options)=>{
    setCookie(CLUSTER_COOKIE, clusterId);
    setUtmClusterId(clusterId);
    if (options.sendBigBrainEvent) {
        trackEvent(SET_CLUSTER_ID_EVENT_NAME, {
            kind: clusterId,
            info1: reason,
            info2: window.location.href
        });
    }
};
const setFinalSubClusterIdAndTrackEventIfNeeded = (subClusterId, reason, options)=>{
    if (subClusterId) {
        setCookie(SUB_CLUSTER_COOKIE, subClusterId);
    }
    if (!subClusterId && getCookie(SUB_CLUSTER_COOKIE)) {
        //	removing the cookie so we won't have sub cluster of another cluster
        removeCookie(SUB_CLUSTER_COOKIE);
    }
    setUtmSubClusterId(subClusterId);
    if (options.sendBigBrainEvent) {
        trackEvent(SET_SUB_CLUSTER_ID_EVENT_NAME, {
            kind: subClusterId,
            info1: reason,
            info2: window.location.href
        });
    }
};
export const setClusterIdIfNeeded = (configClusterId, subClusterId, options = {})=>{
    const clusterReason = options.reason || SET_CLUSTER_FROM_PAGE_CONFIG_REASON;
    if (configClusterId) {
        setFinalClusterIdAndTrackEventIfNeeded(configClusterId, clusterReason, options);
        setFinalSubClusterIdAndTrackEventIfNeeded(subClusterId, clusterReason, options); //	setting if having, or clearing if not
    }
};
//* ********************************* END OF LEGACY UTM CLUSTER AND SUBCLUSTER COOKIE SUPPORT  *************************************************************** */
// ---------------------------------------------- //
// -----  MULTIPLE CLUSTERS ON LOCAL STORAGE ---- //
// ---------------------------------------------- //
const shouldSetClusterToUser = (source)=>{
    //  if the source is cookie, we won't log, as it was logged already
    return ![
        HP_COOKIE,
        PLATFORM_COOKIE
    ].includes(source);
};
export const setClusterForUser = (clusterId, clusterType, clusterSource, clusterExtraData = {})=>{
    //  todo: should move the utm_cluster_id logic into here
    //   and have a "black box" which will set the "right" cluster cookie for the user,
    //   which will be used on the next page load as default value (see getClusterValueAndSource)
    if (clusterId && shouldSetClusterToUser(clusterSource)) {
        updateUserClustersListWithSingleCluster(clusterId, clusterType, clusterSource, {
            clusterExtraData
        });
    }
};
// TODO: ASK Etay - can we keep this functions as is with the subCluster param etc.. ? there is a lot of code depending on it.
const getClusterInfoForCurrentSession = (overrideParams, cookies, pageConfig, options)=>{
    // for making things simpler for now, subclusters must have cluster with them (in param/page config/etc...)
    let clusterId, subClusterId, clusterIdSource, subClusterIdSource;
    const isPricingPage = options.pricingPage; // platform cookies is relevant only for pricing page, and pricing page doesn't use page config
    if (overrideParams[CLUSTER]) {
        clusterId = overrideParams[CLUSTER];
        subClusterId = overrideParams[SUB_CLUSTER];
        clusterIdSource = PARAM;
        subClusterIdSource = subClusterId && PARAM;
    } else if (isPricingPage && cookies[PLATFORM_ACCOUNT_CLUSTER_COOKIE]) {
        clusterId = cookies[PLATFORM_ACCOUNT_CLUSTER_COOKIE];
        subClusterId = cookies[PLATFORM_ACCOUNT_SUB_CLUSTER_COOKIE];
        clusterIdSource = PLATFORM_COOKIE;
        subClusterIdSource = subClusterId && PLATFORM_COOKIE;
    } else if (!isPricingPage && pageConfig.clusterId) {
        clusterId = pageConfig.clusterId;
        subClusterId = pageConfig.subClusterId;
        clusterIdSource = PAGE_CONFIG;
        subClusterIdSource = subClusterId && PAGE_CONFIG;
    } else {
        clusterId = cookies[CLUSTER_COOKIE];
        subClusterId = cookies[SUB_CLUSTER_COOKIE];
        clusterIdSource = clusterId && HP_COOKIE;
        subClusterIdSource = subClusterId && HP_COOKIE;
    }
    return {
        clusterId,
        subClusterId,
        clusterIdSource,
        subClusterIdSource
    };
};
export const parsePageLoadClusters = (overrideParams, cookies, pageConfig, options = {})=>{
    return getClusterInfoForCurrentSession(overrideParams, cookies, pageConfig, options);
};
const createCluster = ({ clusterId, clusterType, attributionTime, source = DEFAULT_HOMEPAGE_SOURCE, extraData = {} })=>{
    return _object_spread({
        cluster_id: clusterId,
        type: clusterType,
        source,
        attribution_time: attributionTime,
        last_seen: attributionTime
    }, extraData);
};
const getIndexOfClusterIfExists = (clustersOfUser, newCluster)=>{
    let indexOfClusterIfExists = null;
    clustersOfUser.some((e, index)=>{
        if (e.cluster_id === newCluster.cluster_id && e.type === newCluster.type && e.source === newCluster.source) {
            indexOfClusterIfExists = index;
            return true;
        }
    });
    return indexOfClusterIfExists;
};
const addOrUpdateClustersIfNeeded = ({ clustersOfUser, newCluster, attributionTime })=>{
    const indexOfClusterIfExists = getIndexOfClusterIfExists(clustersOfUser, newCluster);
    if (indexOfClusterIfExists !== null) {
        clustersOfUser[indexOfClusterIfExists].last_seen = attributionTime;
        return OPERATIONS.UPDATED;
    }
    clustersOfUser.push(newCluster);
    if (clustersOfUser.length > MAX_CLUSTERS) {
        // removeOldestEntry(clustersOfUser); // following up first
        return OPERATIONS.ADDED_AND_REMOVED;
    }
    return OPERATIONS.ADDED;
};
export const getCurrentClusters = ()=>{
    try {
        return JSON.parse(localStorage.getItem(CLUSTERS)) || [];
    } catch (e) {
        return [];
    }
};
const saveClusters = (clustersOfUser)=>{
    localStorage.setItem(CLUSTERS, JSON.stringify(clustersOfUser));
};
const updateCluster = ({ clustersOfUser, clusterId, clusterType, attributionTime, source, extraData = {} })=>{
    if (!clusterId) return;
    const newCluster = createCluster({
        clusterId,
        clusterType,
        attributionTime,
        source,
        extraData
    });
    const operationStatus = addOrUpdateClustersIfNeeded({
        clustersOfUser,
        newCluster,
        attributionTime
    });
    trackEvent(SET_CLUSTER_EVENT, {
        kind: operationStatus,
        info1: clusterId,
        info2: clusterType,
        info3: location.href,
        placement: source,
        data: JSON.stringify(extraData)
    });
};
export const updateUserClustersListWithMultipleClusters = (clusters, source)=>{
    const clustersOfUser = getCurrentClusters();
    const attributionTime = new Date().getTime();
    clusters.forEach((cluster)=>{
        const { clusterId, clusterType, extraData } = cluster;
        updateCluster({
            clustersOfUser,
            clusterId,
            clusterType,
            attributionTime,
            source,
            extraData
        });
    });
    saveClusters(clustersOfUser);
};
export const updateUserClustersListWithSingleCluster = (clusterId, clusterType, source, options = {})=>{
    const { clusterExtraData, setSignupCluster, useCaseId } = options, restOptions = _object_without_properties(options, [
        "clusterExtraData",
        "setSignupCluster",
        "useCaseId"
    ]);
    const clusters = [
        {
            clusterId,
            clusterType,
            extraData: clusterExtraData
        }
    ];
    if (useCaseId) {
        clusters.push({
            clusterId: useCaseId,
            clusterType: USE_CASE_TYPE,
            extraData: _object_spread_props(_object_spread({}, clusterExtraData), {
                parent_cluster_id: clusterId
            })
        });
    }
    updateUserClustersListWithMultipleClusters(clusters, source);
    if (clusterType === CLUSTER_TYPE && setSignupCluster) {
        setClusterIdIfNeeded(clusterId, useCaseId, restOptions);
    }
};
export const getClustersConfig = function() {
    var _ref = _async_to_generator(function*(clusterId) {
        return HOMEPAGE_CLUSTERS_DATA[clusterId];
    });
    return function getClustersConfig(clusterId) {
        return _ref.apply(this, arguments);
    };
}();
export const getClusterIdsFromLastTagsSelection = (clusters)=>{
    const clustersFromTags = clusters.filter((cluster)=>cluster.source === HP_TAGS_SELECTION);
    const lastAttributedCluster = orderBy(clustersFromTags, [
        "last_seen"
    ], [
        "desc"
    ])[0];
    const lastAttributedClusters = clustersFromTags.filter((cluster)=>cluster.last_seen === lastAttributedCluster.last_seen);
    return lastAttributedClusters.map((cluster)=>cluster.cluster_id);
};
export const getProductByClustersIds = (clustersIds)=>{
    // no selection or 'More workflows' is selected ('More workflows' is not assigned with a cluster)
    if (clustersIds.length === 0) {
        return null;
    }
    // all clusters that do not exist in the map, map to work management
    const productsIds = newUniqueArray(clustersIds.map((clusterId)=>clusterIdToProductIdMap[clusterId] || WORK_MANAGEMENT_PRODUCT_ID));
    // selection maps to a single product
    if (productsIds.length === 1) {
        return productsIds[0];
    }
    // selection maps to multiple products - return 'Work management'
    return WORK_MANAGEMENT_PRODUCT_ID;
};
