const countriesList = require("countries-list");
// COUNTRY CODES
const CANADA_ISO2 = "CA";
const ISRAEL_ISO2 = "IL";
const UNITED_STATES_ISO2 = "US";
const MEXICO_ISO2 = "MX";
const SPAIN_ISO2 = "ES";
const PORTUGAL_ISO2 = "PT";
const CHILE_ISO2 = "CL";
const PERO_ISO2 = "PE";
const ARGENTINA_ISO2 = "AR";
const BRAZIL_ISO2 = "BR";
const FRANCE_ISO2 = "FR";
const BELGIUM_ISO2 = "BE";
const GERMANY_ISO2 = "DE";
const AUSTRIA_ISO2 = "AT";
const SWITZERLAND_ISO2 = "CH";
const NETHERLANDS_ISO2 = "NL";
const ITALY_ISO2 = "IT";
const RUSSIA_ISO2 = "RU";
const JAPAN_ISO2 = "JP";
const KOREA_ISO2 = "KO";
const TURKEY_ISO2 = "TR";
const SWEDEN_ISO2 = "SE";
const BULGARIA_ISO2 = "BG";
const NORWAY_ISO2 = "NO";
const DENMARK_ISO2 = "DK";
const POLAND_ISO2 = "PL";
const LUXEMBOURG_ISO2 = "LU";
const HONG_KONG_ISO2 = "HK";
const ICELAND_ISO2 = "IS";
const TAIWAN_ISO2 = "TW";
const AUSTRALIA_ISO2 = "AU";
const UNITED_KINGDOM_ISO2 = "GB";
const IRELAND_ISO2 = "IE";
const SAUDI_ARABIA_ISO2 = "SA";
const NEW_ZEALAND_ISO2 = "NZ";
const COSTA_RICA_ISO2 = "CR";
const EL_SALVADOR_ISO2 = "SV";
const GUATEMALA_ISO2 = "GT";
const HONDURAS_ISO2 = "HN";
const NICARAGUA_ISO2 = "NI";
const PANAMA_ISO2 = "PA";
const CUBA_ISO2 = "CU";
const DOMINICAN_REPUBLIC_ISO2 = "DO";
const PUERTO_RICO_ISO2 = "PR";
const BOLIVIA_ISO2 = "BO";
const COLUMBIA_ISO2 = "CO";
const ECUADOR_ISO2 = "EC";
const PARAGUAY_ISO2 = "PY";
const PERU_ISO2 = "PE";
const URUGUAY_ISO2 = "UY";
const VENEZUELA_ISO2 = "VE";
const EQUATORIAL_GUINEA_ISO2 = "GQ";
const FINLAND_ISO2 = "FI";
const ARMENIA_ISO2 = "AM";
const AZERBAIJAN_ISO2 = "AZ";
const BAHRAIN_ISO2 = "BH";
const BELARUS_ISO2 = "BY";
const CROATIA_ISO2 = "HR";
const CYPRUS_ISO2 = "CY";
const CZECHIA_ISO2 = "CZ";
const EGYPT_ISO2 = "EG";
const ESTONIA_ISO2 = "EE";
const GEORGIA_ISO2 = "GE";
const GREECE_ISO2 = "GR";
const HUNGARY_ISO2 = "HU";
const KAZAKHSTAN_ISO2 = "KZ";
const KENYA_ISO2 = "KE";
const KUWAIT_ISO2 = "KW";
const LATVIA_ISO2 = "LV";
const LITHUANIA_ISO2 = "LT";
const MAURITIUS_ISO2 = "MU";
const MOZAMBIQUE_ISO2 = "MZ";
const NIGERIA_ISO2 = "NG";
const OMAN_ISO2 = "OM";
const PHILIPPINES_ISO2 = "PH";
const QATAR_ISO2 = "QA";
const ROMANIA_ISO2 = "RO";
const RWANDA_ISO2 = "RW";
const SINGAPORE_ISO2 = "SG";
const SLOVAKIA_ISO2 = "SK";
const SLOVENIA_ISO2 = "SL";
const SOUTH_AFRICA_ISO2 = "ZA";
const SOUTH_SUDAN_ISO2 = "SS";
const TANZANIA_ISO2 = "TZ";
const UGANDA_ISO2 = "UG";
const UKRAINE_ISO2 = "UA";
const UNITED_ARAB_EMIRATES_ISO2 = "AE";
const UZBEKISTAN_ISO2 = "UZ";
const AMERICAN_SAMOA_ISO2 = "AS";
const GUAM_ISO2 = "GU";
const NORTHERN_MARIANA_ISLANDS_ISO2 = "MP";
const UNITED_STATES_MINOR_OUTLYING_ISLANDS_ISO2 = "UM";
const MAIN_COUNTRY_CODES = {
    CANADA_ISO2,
    ISRAEL_ISO2,
    UNITED_STATES_ISO2,
    MEXICO_ISO2,
    SPAIN_ISO2,
    PORTUGAL_ISO2,
    CHILE_ISO2,
    PERO_ISO2,
    ARGENTINA_ISO2,
    BRAZIL_ISO2,
    FRANCE_ISO2,
    BELGIUM_ISO2,
    GERMANY_ISO2,
    AUSTRIA_ISO2,
    SWITZERLAND_ISO2,
    NETHERLANDS_ISO2,
    ITALY_ISO2,
    RUSSIA_ISO2,
    JAPAN_ISO2,
    KOREA_ISO2,
    TURKEY_ISO2,
    SWEDEN_ISO2,
    BULGARIA_ISO2,
    NORWAY_ISO2,
    DENMARK_ISO2,
    POLAND_ISO2,
    LUXEMBOURG_ISO2,
    HONG_KONG_ISO2,
    ICELAND_ISO2,
    TAIWAN_ISO2,
    AUSTRALIA_ISO2,
    UNITED_KINGDOM_ISO2,
    IRELAND_ISO2,
    SAUDI_ARABIA_ISO2,
    NEW_ZEALAND_ISO2,
    COSTA_RICA_ISO2,
    EL_SALVADOR_ISO2,
    GUATEMALA_ISO2,
    HONDURAS_ISO2,
    NICARAGUA_ISO2,
    PANAMA_ISO2,
    CUBA_ISO2,
    DOMINICAN_REPUBLIC_ISO2,
    PUERTO_RICO_ISO2,
    BOLIVIA_ISO2,
    COLUMBIA_ISO2,
    ECUADOR_ISO2,
    PARAGUAY_ISO2,
    PERU_ISO2,
    URUGUAY_ISO2,
    VENEZUELA_ISO2,
    EQUATORIAL_GUINEA_ISO2,
    FINLAND_ISO2,
    ARMENIA_ISO2,
    AZERBAIJAN_ISO2,
    BAHRAIN_ISO2,
    BELARUS_ISO2,
    CROATIA_ISO2,
    CYPRUS_ISO2,
    CZECHIA_ISO2,
    EGYPT_ISO2,
    ESTONIA_ISO2,
    GEORGIA_ISO2,
    GREECE_ISO2,
    HUNGARY_ISO2,
    KAZAKHSTAN_ISO2,
    KENYA_ISO2,
    KUWAIT_ISO2,
    LATVIA_ISO2,
    LITHUANIA_ISO2,
    MAURITIUS_ISO2,
    MOZAMBIQUE_ISO2,
    NIGERIA_ISO2,
    OMAN_ISO2,
    QATAR_ISO2,
    ROMANIA_ISO2,
    RWANDA_ISO2,
    SLOVAKIA_ISO2,
    SLOVENIA_ISO2,
    SOUTH_AFRICA_ISO2,
    SOUTH_SUDAN_ISO2,
    TANZANIA_ISO2,
    UGANDA_ISO2,
    UKRAINE_ISO2,
    UNITED_ARAB_EMIRATES_ISO2,
    UZBEKISTAN_ISO2
};
const NON_NATIVE_ENGLISH_COUNTRIES_ISO2 = [
    MEXICO_ISO2,
    SPAIN_ISO2,
    CHILE_ISO2,
    PERO_ISO2,
    ARGENTINA_ISO2,
    BRAZIL_ISO2,
    FRANCE_ISO2,
    CANADA_ISO2,
    BELGIUM_ISO2,
    GERMANY_ISO2,
    AUSTRIA_ISO2,
    SWITZERLAND_ISO2,
    NETHERLANDS_ISO2,
    ITALY_ISO2,
    RUSSIA_ISO2,
    JAPAN_ISO2,
    KOREA_ISO2,
    TURKEY_ISO2,
    SWEDEN_ISO2,
    POLAND_ISO2,
    HONG_KONG_ISO2,
    TAIWAN_ISO2
];
// CONTINENT CODES
const OCEANIA = "OC"; // Australia, New Zealand + surrounding islands and countries
const EUROPE = "EU";
const NORTH_AMERICA = "NA";
// Special list of countries - US territories in the Oceania continent
const US_OCEANIC_TERRITORIES = [
    AMERICAN_SAMOA_ISO2,
    GUAM_ISO2,
    NORTHERN_MARIANA_ISLANDS_ISO2,
    UNITED_STATES_MINOR_OUTLYING_ISLANDS_ISO2
];
const getCountriesByContinent = (continentCode)=>{
    const { countries } = countriesList;
    const countryCodes = Object.keys(countries);
    return countryCodes.filter((cc)=>countries[cc].continent === continentCode);
};
const isCountryInContinent = (countryCode, continentCode)=>{
    return getCountriesByContinent(continentCode).includes(countryCode);
};
const getContientByCountry = (countryCode)=>{
    var _countries_countryCode;
    const { countries } = countriesList;
    return (_countries_countryCode = countries[countryCode]) === null || _countries_countryCode === void 0 ? void 0 : _countries_countryCode.continent;
};
const COUNTRIES_DROPDOWN_OPTIONS = [
    {
        value: MAIN_COUNTRY_CODES.ISRAEL_ISO2,
        text: "Israel"
    },
    {
        value: MAIN_COUNTRY_CODES.UNITED_STATES_ISO2,
        text: "United States"
    },
    {
        value: MAIN_COUNTRY_CODES.AUSTRALIA_ISO2,
        text: "Australia"
    },
    {
        value: MAIN_COUNTRY_CODES.UNITED_KINGDOM_ISO2,
        text: "United Kingdom"
    },
    {
        value: MAIN_COUNTRY_CODES.PHILIPPINES_ISO2,
        text: "Philippines"
    },
    {
        value: MAIN_COUNTRY_CODES.SINGAPORE_ISO2,
        text: "Singapore"
    },
    {
        value: MAIN_COUNTRY_CODES.CANADA_ISO2,
        text: "Canada"
    }
];
module.exports = {
    // COUNTRIES ISO2
    MAIN_COUNTRY_CODES,
    CANADA_ISO2,
    UNITED_STATES_ISO2,
    UNITED_KINGDOM_ISO2,
    AUSTRALIA_ISO2,
    ISRAEL_ISO2,
    NON_NATIVE_ENGLISH_COUNTRIES_ISO2,
    BRAZIL_ISO2,
    SPAIN_ISO2,
    PORTUGAL_ISO2,
    PHILIPPINES_ISO2,
    SINGAPORE_ISO2,
    US_OCEANIC_TERRITORIES,
    // CONTINENTS
    OCEANIA,
    EUROPE,
    NORTH_AMERICA,
    COUNTRIES_DROPDOWN_OPTIONS,
    // FUNCTIONS
    getCountriesByContinent,
    isCountryInContinent,
    getContientByCountry
};
